@use '~/theme/variables/index.scss' as theme;
@use '~/theme/mixins/index.scss' as *;

.ratingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: theme.$color-grey100;
    cursor: pointer;

    @include breakpoint('sm') {
        padding-top: theme.$space-2;
    }

    .ratingTotalRating {
        color: theme.$color-grey100;
        margin-left: theme.$space-1;
        font-style: normal;
        font-weight: 400;
        font-size: theme.$font-size-2xs;
        line-height: 15px;
        margin-left: theme.$space-1;
    }

    .ratingStar {
        width: theme.$font-size-2xs;
        margin-right: 2px;
        height: 100%;
        display: inline-flex;

        svg {
            width: 100%;
        }

        @include breakpoint('sm') {
            width: theme.$font-size-sm;
        }
    }
}
