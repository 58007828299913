@use '~/theme/variables/index.scss' as theme;
@use '~/theme/mixins/index.scss' as *;

.campaignRedirecterContainer {
    display: flex;
    justify-content: space-between;
    padding: theme.$space-3;
    font-weight: 500;
    font-size: theme.$font-size-xs;
    border-left: 2px solid theme.$color-orangeMedium;
    margin-bottom: theme.$space-2;
    background-color: theme.$color-grey10;

    @include breakpoint('sm') {
        font-size: theme.$font-size-sm;
    }

    .campaignRedirecterTranslation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: theme.$color-grey10;
    }

    .campaignRedirecterText {
        display: flex;
        justify-content: space-between;
        background-color: theme.$color-grey10;
    }

    .campaignRedirecterDetails {
        margin-right: theme.$space-1;
    }
}
