@use 'sass:map';

@use '~/theme/variables/index.scss' as theme;

/**
 * Validate whether key exists in breakpoints map.
 * Ex.: @mixin isValidBreakpoint('sm') // true
 */
@mixin isValidBreakpoint($key) {
    $is-valid: map.has-key(theme.$breakpoints, $key);

    @if ($is-valid == false) {
        @error 'ERROR: Invalid size. "'#{$key}'" does not exist in $breakpoints.';
    }
}

/**
 * Used for adding breakpoint media queries.
 * Ex.: @mixin breakpoint('sm') {}
 */
@mixin breakpoint($key, $direction: 'up') {
    @include isValidBreakpoint($key);

    @if ($direction == 'up') {
        @media screen and (min-width: map.get(theme.$breakpoints, $key)) {
            @content;
        }
    } @else if ($direction == 'down') {
        @media screen and (max-width: #{map.get(theme.$breakpoints, $key) - 1}) {
            @content;
        }
    } @else {
        @error 'ERROR: Invalid direction. Only "up" or "down" is allowed.';
    }
}
