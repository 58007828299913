@use '~/theme/variables/index.scss' as theme;
@use '~/theme/mixins/index.scss' as *;

.container {
    margin: 0px auto;
    max-width: theme.$size-contentMaxWidth;
    width: 100%;
}

.gutter {
    padding-left: theme.$space-4;
    padding-right: theme.$space-4;
    @include breakpoint('max') {
        padding-left: 0;
        padding-right: 0;
    }
}

.verticalGutter {
    padding-top: theme.$space-5;
    padding-bottom: theme.$space-5;
}
