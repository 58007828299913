@use '~/theme/variables/index.scss' as theme;
@use '~/theme/mixins/index.scss' as *;

.storeDetailsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    width: 100%;
}

.storeDetailsContent {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoint('md') {
        min-width: theme.$size-left-content;
        flex-direction: row;
    }
}
