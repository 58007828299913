// Variables for theme or sizes can be imported if needed
@use '~/theme/variables/index.scss' as theme;
@use '~/theme/mixins/index.scss' as *;

$switchTriggerSize: 22px;

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;

    &:has(:focus-visible) {
        box-shadow: theme.$shadow-accessibility;
    }
}

.fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

.input {
    cursor: pointer;
    position: absolute;
    inset: 0;
    opacity: 0;

    &:checked ~ .switchIndicator {
        background: theme.$color-black;

        &::after {
            left: calc(100% - #{$switchTriggerSize} - 1px);
        }
    }

    &:disabled ~ .switchIndicator {
        background: theme.$color-grey30;

        &::after {
            opacity: 0.8;
        }
    }
}

.legend {
    display: block;
    margin-right: theme.$space-3;
    cursor: pointer;
    font-size: theme.$font-size-xs;
    line-height: 20px;

    &.valid {
        color: theme.$color-black;
    }

    &.invalid {
        color: theme.$color-redMedium;
    }

    &.disabled {
        color: theme.$color-grey30;
        cursor: not-allowed;
    }

    @include breakpoint('sm') {
        font-size: 13px;
        line-height: 19px;
    }
}

.switchIndicator {
    display: block;
    position: relative;
    width: 45px;
    height: 24px;
    overflow: hidden;
    border-radius: 22px;
    pointer-events: none;
    background: theme.$color-brownLight;
    transition: background 0.1s;

    &::after {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        top: 1px;
        left: 1px;
        width: 22px;
        height: 22px;
        border-radius: 22px;
        overflow: hidden;
        background: theme.$color-white;
        transition: left 0.1s linear;
    }
}

.switch-help-texts {
    margin-left: calc(25px + theme.$space-3);
}
