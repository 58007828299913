.spinner {
    --offset: 187;
    --duration: 1.4s;
    animation: rotator var(--duration) ease-in-out infinite;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.path {
    stroke-dasharray: var(--offset);
    stroke-dashoffset: 0;
    transform-origin: center;
    /* animation: colors calc(var(--duration) * 4) ease-in-out infinite; */
}

@keyframes colors {
    0% {
        stroke: #ffffff;
        fill: #ffffff;
    }
    25% {
        stroke: #f68b1f;
        fill: #f68b1f;
    }
    50% {
        stroke: #86888a;
        fill: #86888a;
    }
    75% {
        stroke: #f04e23;
        fill: #f04e23;
    }
    100% {
        stroke: #ffffff;
        fill: #ffffff;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: var(--offset);
        fill-dashoffset: var(--offset);
    }
    50% {
        stroke-dashoffset: calc(var(--offset) / 4);
        transform: rotate(135deg);
    }
    100% {
        stroke-dashoffset: var(--offset);
        transform: rotate(450deg);
    }
}
