@use '~/theme/variables/index.scss' as theme;
@use '~/theme/mixins/index.scss' as *;

.freightComponent {
    padding: 16px;
    text-align: center;
    color: theme.$color-white;
    background-color: theme.$color-greenMedium;

    .freightHeadline {
        text-transform: uppercase;
        font-size: theme.$font-size-sm;
        font-weight: 500;
        line-height: 20px;

        @include breakpoint('sm') {
            line-height: initial;
        }
    }

    .freightCost {
        font-size: theme.$font-size-xs;
    }
}
